<template>
  <div>
    <v-row>
      <!-- <v-col class="d-flex" cols="3" sm="3">
        <v-select
          :items="typeData"
          v-model="typeBill"
          item-text="label"
          item-value="value"
          label="ประเภท"
          solo
        ></v-select>
      </v-col> -->
      <v-row class="pb-15">
        <div>
          <v-btn block @click="handleBack">
            <v-icon dark left> mdi-arrow-left </v-icon>
            กลับ
          </v-btn>
        </div>
        <div class="px-5">
          <v-btn block @click="billPDF">
            <v-icon dark left> mdi-printer </v-icon>
            PDF
          </v-btn>
        </div>
        <div class="pr-5">
          <v-btn block @click="billPDFsmall">
            <v-icon dark left> mdi-printer </v-icon>
            PDF small
          </v-btn>
        </div>
        <div>
          <v-btn block @click="dialogUserAddress = true">
            <v-icon dark left> mdi-map-marker </v-icon>
            ที่อยู่ลูกค้า
          </v-btn>
        </div>
      </v-row>
    </v-row>

    <!-- address -->
    <v-dialog v-model="dialogUserAddress" persistent max-width="600px">
      <v-card>
        <v-card-title> แก้ไขที่อยู่ </v-card-title>
        <v-card-text>
          <v-form ref="formEdit" v-model="validEdit" lazy-validation class="multi-col-validation">
            <v-row id="form-address">
              <v-col cols="12" md="6">
                <v-text-field
                  type="text"
                  v-model="userAddress.full_name"
                  :rules="rules"
                  required
                  outlined
                  dense
                  placeholder="ชื่อ-นามสกุล"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  v-model="userAddress.tax_number"
                  :rules="idCardRules"
                  required
                  outlined
                  dense
                  placeholder="หมายเลขผู้เสียภาษี"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  v-model="userAddress.phone"
                  :rules="rulesPhone"
                  required
                  outlined
                  dense
                  placeholder="เบอร์โทร"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userAddress.address"
                  :rules="rules"
                  outlined
                  dense
                  placeholder="ที่อยู่"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userAddress.zip_code"
                  :rules="rules"
                  outlined
                  dense
                  placeholder="รหัสไปรษณีย์"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userAddress.district"
                  :rules="rules"
                  outlined
                  dense
                  placeholder="ตำบล/แขวง"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userAddress.amphure"
                  :rules="rules"
                  outlined
                  dense
                  placeholder="อำเภอ/เขต"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-text-field
                  v-model="userAddress.province"
                  :rules="rules"
                  outlined
                  dense
                  placeholder="จังหวัด"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCancel"> ยกเลิก </v-btn>
          <v-btn color="primary" :disabled="!validEdit" @click="handleEdit"> บันทึก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="d-flex flex-column" tile size="A4" id="paper">
      <v-card elevation="0">
        <div class="pa-5 pt-4">
          <div class="d-flex">
            <div class="mr-auto">
              <v-col class="d-flex flex-column align-left">
                <v-img :src="logoBranch" width="100"></v-img>
              </v-col>
              <v-col>
                <div class="text-sm pb-1">{{ clinicAddress.address }}</div>
                <div class="text-sm pb-1">ต.{{ clinicAddress.district }} อ.{{ clinicAddress.amphure }}</div>
                <div class="text-sm pb-1">จ.{{ clinicAddress.province }} {{ clinicAddress.zip_code }}</div>
                <div class="text-sm pb-1">TaxID {{ clinicAddress.tax_number }}</div>
                <div class="text--black text-sm">{{ clinicAddress.full_name }}</div>
              </v-col>
            </div>
            <div class="text-sm align-self-end mr-13">
              <v-col class="text-left">
                <div class="text-xs pb-1">
                  <span class="text--black"> Tax Invoice No. </span> S{{ detailBill.receipt_code }}
                </div>
                <div class="text-xs">
                  <span class="text--black"> Tax Invoice Date. </span> {{ formatDate(detailBill.receipt_date) }}
                </div>
              </v-col>
            </div>
            <div>
              <div class="text-h3 text-right pt-2">Invoice</div>
              <v-col class="text-right pt-5" v-if="address != ''">
                <div class="text--black pb-1">{{ address.full_name }}</div>
                <div class="text-sm pb-1">Tax ID {{ address.tax_number }}</div>
                <div class="text-sm pb-1">{{ address.address }}</div>
                <div class="text-sm pb-1">ต.{{ address.district }} อ.{{ address.amphure }}</div>
                <div class="text-sm pb-1">{{ address.province }}</div>
                <div class="text-sm pb-1">{{ address.zip_code }}</div>
                <div class="text-sm">ประเทศไทย</div>
              </v-col>
              <v-col v-else class="text-right pt-5">
                <div class="text--black text-sm pb-1">
                  ลูกค้า {{ detailBill.user.fname }} {{ detailBill.user.lname }}
                </div>
              </v-col>
            </div>
          </div>
          <div>
            <v-col>
              <div class="text-sm pb-1">หัวข้อ</div>
              <div class="text--black">สินค้าและบริการของ {{ clinicAddress.full_name }}</div>
            </v-col>
          </div>
          <div>
            <v-col>
              <v-row>
                <v-col cols="3">
                  <div>หมายเลขใบเสร็จ</div>
                  <div>#{{ detailBill.order_num }}</div>
                </v-col>
                <v-col cols="9">
                  <div>
                    <div v-if="detailBill.remain != 0" class="text-right">
                      <div>
                        ยอดทั้งหมดของออเดอร์
                        {{
                          detailBill.vat_type == 2
                            ? formatFloat(detailBill.total_all - detailBill.vat_price)
                            : formatFloat(detailBill.total_all)
                        }}
                        บาท
                      </div>
                      <div v-if="detailBill.vat_type != 0">
                        ภาษีมูลค่าเพิ่ม
                        {{ detailBill.vat_type == 1 ? '(inc) 7%' : '(exc) 7%' }}
                        {{ formatFloat(detailBill.vat_price) }} บาท
                      </div>
                    </div>
                    <v-card outlined tile class="rounded">
                      <v-simple-table dense id="billing">
                        <template v-slot:default>
                          <tr>
                            <th class="border-bottom text-left text-sm py-2 pl-2">ลำดับ</th>
                            <th class="border-bottom text-left text-sm pl-3">รายละเอียดรายการ</th>
                            <th class="border-bottom text-sm">จำนวน</th>
                            <th class="border-bottom text-sm text-right pr-3">ราคารวม</th>
                          </tr>

                          <tbody>
                            <tr v-for="(item, index) in receiptList" :key="index" style="vertical-align: text-top;">
                              <td>{{ index + 1 }}</td>
                              <td class="text-left pa-3">
                                <div class="font-weight-semibold">{{ item.receipt_name }}</div>

                                <div v-if="item.product.length != 0 || item.service.length != 0" style="font-size:12px">
                                  <tr>
                                    <td class="pr-5">ส่วนประกอบ</td>
                                    <td class="pr-5">จำนวน</td>
                                    <td>ราคา</td>
                                  </tr>
                                  <tbody>
                                    <tr v-for="(item, index) in item.product" :key="index + '_product'">
                                      <td class="pr-5">{{ item.name_th }}</td>
                                      <td class="pr-5">{{ item.amount }}</td>
                                      <td>{{ formatInt(item.price) }}</td>
                                    </tr>
                                    <tr v-for="(item, index) in item.service" :key="index + '_service'">
                                      <td class="pr-5">{{ item.name_th }}</td>
                                      <td class="pr-5">{{ item.amount }}</td>
                                      <td>{{ formatInt(item.price) }}</td>
                                    </tr>
                                  </tbody>
                                </div>
                              </td>

                              <td class="text-right">{{ item.amount }}</td>
                              <td class="text-right text-no-wrap">฿ {{ formatInt(item.total_pay) }}</td>
                            </tr>
                            <tr>
                              <td colspan="4" class="border-top">
                                <div class="d-flex justify-space-between">
                                  <span class="text--black">ราคารวม</span>
                                  <span class="text--black text-right">
                                    ฿
                                    {{ formatFloat(price_total) }}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="detailBill.num_pay == 1">
                              <td colspan="4">
                                <div class="d-flex justify-space-between">
                                  <span class="text-no-wrap">
                                    ภาษีมูลค่าเพิ่ม
                                    {{
                                      detailBill.vat_type == 0 ? '' : detailBill.vat_type == 1 ? '(inc) 7%' : '(exc) 7%'
                                    }}
                                  </span>

                                  <span class="text-right">
                                    ฿ {{ detailBill.num_pay > 1 ? 0 : formatFloat(detailBill.vat_price) }}
                                  </span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4" class="border-top py-3">
                                <div class="d-flex justify-space-between">
                                  <span class="font-weight-semibold" style="color: #00205c">ราคาสุทธิ</span>
                                  <span class="text-right text-h6 text-no-wrap" style="color: #9d1a3a">
                                    ฿ {{ formatFloat(detailBill.total_pay) }}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                    <v-card elevation="0" class="pt-5 text-sm">
                      <div>หมายเหตุ</div>
                      <div>
                        {{ detailBill.note == '' ? '-' : detailBill.note }}
                      </div>
                    </v-card>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </div>
        </div>
      </v-card>
      <v-card class="mt-auto" elevation="0">
        <v-col class="pa-5 pt-4">
          <v-row>
            <div class="col mt-auto text-sm">
              <div class="font-weight-semibold">รายละเอียดการชำระ</div>
              <div v-if="!checkRefund">
                <div v-if="detailBill.payment">การชำระ : {{ paymentMethod }}</div>
              </div>
              <div v-else>
                <div>Refund Date : {{ formatDateNTime(detailBill.updated_at) }}</div>
                <div style="color: #9c0e05; max-width: 500px">หมายเหตุ : {{ detailBill.note_cancel }}</div>
              </div>
            </div>
            <div class="text-sm pr-5 text-center">
              <div v-if="signature.message != 'error'">
                <v-img :src="url + signature.signature_picture" width="100" class="ml-auto mr-auto"></v-img>
                <div>( {{ signature.signature_name }} )</div>
              </div>
              <div class="font-weight-semibold">____________________</div>
              <div>ลายมือชื่อดิจิทัล</div>
              <div>วันที่ {{ formatDateNTime(detailBill.created_at) }}</div>
            </div>
          </v-row>
          <hr class="my-3" />
          <div class="d-flex mt-5">
            <div class="mr-auto" style="color: #bb75ee">{{ clinicAddress.phone }}</div>
          </div>
        </v-col>
      </v-card>
    </v-card>
    <p style="page-break-after: always">&nbsp;</p>
    <p style="page-break-before: always">&nbsp;</p>
  </div>
</template>
<script>
import FormatNumber from '@/helper/function'
import moment from 'moment'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { THSrabunnew, THSrabunBold } from '@/assets/fontPDF/THSarabunnew'
import config from '../../../config'
import { configcode } from '@/config/bzbsconfig.js'
// import logoImage from '@/assets/imagesPDF/logo'
// import textLogo from '@/assets/imagesPDF/text_logo'

export default {
  data() {
    return {
      url: configcode.basePublic,
      idParam: this.$route.params.id,
      logoBranch: localStorage.getItem('logo_branch'),
      detailBill: {},
      address: [
        {
          full_name: '',
          tax_number: '',
          address: '',
          district: '',
          amphure: '',
          province: '',
          zip_code: '',
        },
      ],
      clinicAddress: [],
      receiptList: [],
      typeBill: 'Ordinary',
      typeData: [
        { value: 'Ordinary', label: 'บุคคลธรรมดา' },
        { value: 'Juristic', label: 'นิติบุคคล' },
      ],
      price_total: 0,
      imageSmall: new Image(),
      signature: '',
      imgSignature: new Image(),
      imgSig: '',
      dialogUserAddress: false,

      rules: [v => !!v || 'กรุณากรอกข้อมูล'],
      rulesPhone: [v => !!v || 'กรุณากรอกข้อมูล', v => v.length <= 10 || 'เบอร์โทร 9-10 ตัวอักษร'],
      idCardRules: [v => !!v || 'กรุณากรอกข้อมูล', v => v.length == 13 || 'หมายเลขผู้เสียภาษี 13 ตัว'],
      validEdit: false,
      userAddress: {
        full_name: '',
        tax_number: '',
        address: '',
        district: '',
        amphure: '',
        province: '',
        zip_code: '',
      },
    }
  },
  mounted() {
    this.getReceiptOrderById()
    this.getClinicAddress()
    this.getSignature()
  },
  computed: {
    checkRefund() {
      if (this.detailBill.status == 5) {
        return true
      } else {
        return false
      }
    },
    paymentMethod() {
      if (this.detailBill.payment[0].payment_method == 1) {
        return 'เงินสด'
      } else if (this.detailBill.payment[0].payment_method == 2) {
        return 'โมบายแบงค์กิ้ง'
      } else if (this.detailBill.payment[0].payment_method == 3) {
        return 'บัตรเครดิต'
      } else {
        return 'อื่นๆ'
      }
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.imageSmall = new Image()
      this.imgSignature = new Image()
      this.logoBranch = localStorage.getItem('logo_branch')
      setTimeout(() => {
        this.imageSmall.src = this.logoBranch
        this.imgSignature.src = this.imgSig
      })
    },
    handleBack() {
      this.$router.push('/list-order-daily')
    },
    formatInt(num) {
      return FormatNumber.formatInt(num)
    },
    formatFloat(num) {
      return FormatNumber.formatFloat(num)
    },
    formatDateNTime(date) {
      return moment(date).format('DD-MM-YYYY / HH:mm น.')
    },
    formatDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    getClinicAddress() {
      this.$store.dispatch('getClinicAddress').then(res => {
        console.log(res)
        this.clinicAddress = res[0]
      })
    },

    getReceiptOrderById() {
      this.$store.dispatch('getReceiptOrderById', this.idParam).then(res => {
        console.log(res)
        this.detailBill = res
        if (res.num_pay > 1) {
          this.price_total = res.total_pay
        } else {
          this.price_total = res.before_vat_price
        }
        if (res.receipt_address.length == 0) {
          this.address = ''
        } else {
          this.address = res.receipt_address[0]
          this.userAddress = res.receipt_address[0]
        }

        var array = []
        for (var i = 0; i < res.receipt_list.length; i++) {
          array.push({
            index: i + 1,
            receipt_name: res.receipt_list[i].receipt_name,
            amount: res.receipt_list[i].amount,
            total_pay: res.receipt_list[i].total_pay,
            product: res.receipt_list[i].product,
            service: res.receipt_list[i].service,
            receipt_type: res.receipt_list[i].receipt_type,
          })
        }
        this.receiptList = array.filter(function(item) {
          if (item.receipt_type == 'product') {
            return item
          } else if (item.total_pay != 0) {
            return item
          }
        })
      })
    },
    getSignature() {
      this.$store.dispatch('getSignature').then(res => {
        console.log(res)
        console.log(res.signature_picture)
        this.signature = res
        this.imgSig = this.url + res.signature_picture
      })
    },

    handleEdit() {
      if (this.$refs.formEdit.validate()) {
        var data = {
          id: this.idParam,
          full_name: this.userAddress.full_name,
          tax_number: this.userAddress.tax_number,
          phone: this.userAddress.phone,
          address: this.userAddress.address,
          district: this.userAddress.district,
          amphure: this.userAddress.amphure,
          province: this.userAddress.province,
          zip_code: this.userAddress.zip_code,
          type: 0,
          branch_no: '',
        }
        this.$store.dispatch('editUserAddress', data).then(res => {
          console.log(res)
          this.getReceiptOrderById()
          this.dialogUserAddress = false
        })
      }
    },
    handleCancel() {
      this.getReceiptOrderById()
      this.dialogUserAddress = false
    },
    checkTypeImage(url) {
      let tyep = null
      if (url.match(/\.(jpeg|jpg)$/)) {
        tyep = 'JPEG'
      }
      if (url.match(/\.(png)$/)) {
        tyep = 'PNG'
      }
      if (url.match(/\.(gif)$/)) {
        tyep = 'GIF'
      }
      return tyep
      // return url.match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    billPDF() {
      const doc = new jsPDF()

      doc.addFileToVFS('THSarabunNew Bold.ttf', THSrabunBold)
      doc.addFileToVFS('THSarabunNew.ttf', THSrabunnew)
      doc.addFont('THSarabunNew Bold.ttf', 'THSarabunNew', 'bold')
      doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal')
      doc.setFont('THSarabunNew', 'bold')

      var center = 'center'
      var width = doc.internal.pageSize.getWidth()
      var height = doc.internal.pageSize.getHeight()

      // var img = new Image()
      // this.imageSmall.src = this.logoBranch
      var typeImg = this.checkTypeImage(this.logoBranch)

      var calImg = function(width, height) {
        var ratio = Math.min(28 / width, 28 / height)
        return {
          width: width * ratio,
          height: height * ratio,
        }
      }
      var ratioImg = calImg(this.imageSmall.width, this.imageSmall.height)
      doc.addImage(this.imageSmall, typeImg, 15, 12, ratioImg.width, ratioImg.height)

      doc.setFontSize(14)
      doc.setTextColor(105, 105, 105)
      doc.setFont('THSarabunNew', 'normal')
      doc.text(this.clinicAddress.address, 15, 45)
      doc.text('ต.' + this.clinicAddress.district + ' อ.' + this.clinicAddress.amphure, 15, 51)
      doc.text('จ.' + this.clinicAddress.province + ' ' + this.clinicAddress.zip_code, 15, 57)
      doc.text('TaxID ' + this.clinicAddress.tax_number, 15, 63)
      doc.setTextColor(0, 0, 0)

      doc.setFont('THSarabunNew', 'bold')
      doc.setFontSize(13)
      doc.text(this.clinicAddress.full_name, 15, 69)
      doc.text('Tax Invoice No. S' + this.detailBill.receipt_code, 80, 66)
      doc.text('Tax Invoice Date. ' + this.formatDate(this.detailBill.receipt_date), 80, 72)
      doc.setFont('THSarabunNew', 'normal')

      doc.setFontSize(48)
      doc.setTextColor(111, 111, 132)
      doc.setFont('THSarabunNew', 'bold')
      doc.text('Invoice', 158, 16)

      if (this.address != '') {
        doc.setFontSize(16)
        doc.setTextColor(0, 0, 0)
        doc.text(this.address.full_name, 195, 25, { align: 'right' })

        doc.setFontSize(14)
        doc.setFont('THSarabunNew', 'normal')

        doc.text('Tax ID ' + this.address.tax_number, 195, 31, { align: 'right' })
        doc.text(this.address.address, 195, 36, { align: 'right' })
        doc.text('ต.' + this.address.district + ' อ.' + this.address.amphure, 195, 42, { align: 'right' })
        doc.text(this.address.province, 195, 48, { align: 'right' })
        doc.text(this.address.zip_code, 195, 54, { align: 'right' })
        doc.text('ประเทศไทย', 195, 60, { align: 'right' })
      } else {
        doc.setFontSize(14)
        doc.setTextColor(0, 0, 0)
        doc.text('ลูกค้า ' + this.detailBill.user.fname + ' ' + this.detailBill.user.lname, 195, 30, { align: 'right' })
      }
      doc.setFontSize(14)
      doc.setFont('THSarabunNew', 'normal')
      doc.setTextColor(105, 105, 105)
      doc.text('หัวข้อ', 15, 80)
      doc.setTextColor(0, 0, 0)
      doc.setFont('THSarabunNew', 'bold')
      doc.text('สินค้าและบริการของ' + this.clinicAddress.full_name, 15, 86)
      doc.setFontSize(14)
      doc.setFont('THSarabunNew', 'normal')

      doc.setTextColor(105, 105, 105)
      doc.text('หมายเลขใบเสร็จ', 15, 95)
      doc.setTextColor(0, 0, 0)
      doc.text('#' + this.detailBill.order_num, 15, 101)

      if (this.detailBill.remain != 0) {
        doc.text(
          'ยอดทั้งหมดของออเดอร์ ' +
            `${
              this.detailBill.vat_type == 2
                ? this.formatFloat(this.detailBill.total_all - this.detailBill.vat_price).toString()
                : this.formatFloat(this.detailBill.total_all).toString()
            }` +
            ' บาท',
          195,
          83,
          { align: 'right' },
        )
        doc.text('ภาษีมูลค่าเพิ่ม ' + `${this.formatFloat(this.detailBill.vat_price).toString()}` + ' บาท', 195, 89, {
          align: 'right',
        })
      }

      var array = []
      for (var i = 0; i < this.receiptList.length; i++) {
        this.receiptList[i].allPNS = this.receiptList[i].product.concat(this.receiptList[i].service)
      }
      array = this.receiptList

      doc.autoTable({
        //ตารางสินค้าและบริการ
        didDrawCell: function(data) {
          if (data.cell.section === 'body') {
            doc.autoTable({
              startY: data.cell.y + 12,
              margin: { left: 70 },
              tableWidth: 'wrap',
              theme: 'grid',
              styles: {
                font: 'THSarabunNew',
                fontStyle: 'bold',
                fontSize: 10, //14px
                lineColor: [255, 255, 255],
                lineWidth: 0.01, //1px
                cellPadding: 2,
                textColor: [90, 90, 90],
                fillColor: [255, 255, 255], //false for transparent or number or array of number
                valign: 'middle', //top, middle, bottom
                halign: 'left', //left, center, right
                cellWidth: 'auto', //'auto', 'wrap' or a number
                overflow: 'ellipsize', //visible, hidden, ellipsize or linebreak
              },
              body: array[data.row.index].allPNS,
              columns: [
                { header: 'ส่วนประกอบ', dataKey: 'name_th' },
                { header: 'จำนวน', dataKey: 'amount' },
                { header: 'ราคา', dataKey: 'price' },
              ],
              columnStyles: {
                0: { halign: 'left' },
                1: { halign: 'center' },
                2: { halign: 'center' },
              },
            })
          }
        },
        margin: { left: 55 },
        startY: 93,
        theme: 'plain',
        showHead: 'firstPage',
        showFoot: 'lastPage',
        styles: {
          font: 'THSarabunNew',
          fontStyle: 'bold',
          fontSize: 13, //14px
          lineColor: [220, 220, 220],
          lineWidth: 0.01, //1px
          cellPadding: 2,
          textColor: [90, 90, 90],
          fillColor: [255, 255, 255], //false for transparent or number or array of number
          valign: 'top', //top, middle, bottom
          halign: 'center', //left, center, right
          cellWidth: 'auto', //'auto', 'wrap' or a number
          overflow: 'linebreak', //visible, hidden, ellipsize or linebreak
        },

        headStyles: { fillColor: [245, 245, 245] },
        head: [
          [
            { content: 'ลำดับ', styles: { halign: 'center', textColor: [105, 105, 105] } },
            { content: 'รายละเอียดรายการ', styles: { halign: 'center', textColor: [105, 105, 105] } },
            { content: 'จำนวน', styles: { halign: 'center', textColor: [105, 105, 105] } },
            { content: 'ราคา', styles: { halign: 'center', textColor: [105, 105, 105] } },
          ],
        ],
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'left', cellWidth: 80 },
          2: { halign: 'center' },
          3: { halign: 'center' },
        },

        body: this.receiptList,
        columns: [
          { header: 'ลำดับ', dataKey: 'index' },
          { header: 'รายละเอียดรายการ', dataKey: 'receipt_name' },
          { header: 'จำนวน', dataKey: 'amount' },
          { header: 'ราคา', dataKey: 'total_pay' },
        ],
        didParseCell: function(data) {
          let idx = data.row.index
          if (data.row.section === 'body' && data.column.dataKey === 'total_pay') {
            data.cell.text = data.cell.text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          if (data.row.section === 'body' && data.column.dataKey === 'index') {
            data.cell.text = (idx + 1).toString()
          }
          if (data.row.section === 'body' && data.column.dataKey === 'receipt_name') {
            data.cell.styles.minCellHeight =
              array[idx].allPNS.length == 0
                ? 10
                : array[idx].allPNS.length == 1
                ? 30
                : array[idx].allPNS.length * 5 + (array[idx].allPNS.length + array[idx].allPNS.length + 30)
          }
        },
        foot: [
          [
            { content: '   ราคารวม', colSpan: 3, styles: { halign: 'left' } },
            {
              content: `฿ ${this.formatFloat(this.price_total)}`,
              styles: { overflow: 'visible', columnWidth: 'wrap' },
            },
          ],
          [
            {
              content: `   ภาษีมูลค่าเพิ่ม ${
                this.detailBill.vat_type == 0 ? '' : this.detailBill.vat_type == 1 ? '(inc) 7%' : '(exc) 7%'
              }`,
              colSpan: 3,
              styles: { halign: 'left', textColor: [105, 105, 105] },
            },
            {
              content: `฿ ${this.detailBill.num_pay > 1 ? 0 : this.formatFloat(this.detailBill.vat_price)} `,
              styles: { textColor: [105, 105, 105], overflow: 'visible', columnWidth: 'wrap' },
            },
          ],
          [
            { content: ' ราคาสุทธิ', colSpan: 3, styles: { halign: 'left', cellPadding: 4, fontSize: 16 } },
            {
              content: `฿ ${this.formatFloat(this.detailBill.total_pay)}`,
              styles: {
                cellPadding: 4,
                fontSize: 20,
                textColor: [220, 20, 60],
                fontStyle: 'bold',
                overflow: 'visible',
                columnWidth: 'wrap',
              },
            },
          ],
        ],
      })
      doc.autoTable({
        margin: { left: 54 },
        theme: 'plain',
        showHead: 'firstPage',
        styles: {
          font: 'THSarabunNew',
          fontSize: 13,
          lineWidth: 0,
          cellPadding: 1,
        },
        head: [[{ content: 'หมายเหตุ', styles: { halign: 'left', textColor: [105, 105, 105] } }]],
        foot: [[{ content: `${this.detailBill.note == '' ? '-' : this.detailBill.note}`, styles: { halign: 'left' } }]],
      })

      doc.setFont('THSarabunNew', 'bold')
      doc.text(15, !this.checkRefund ? 266 : 260, 'รายละเอียดการชำระ')
      doc.setFont('THSarabunNew', 'normal')
      if (!this.checkRefund) {
        doc.text(15, 272, 'การชำระ : ' + `${this.paymentMethod}`)
      } else {
        doc.text(15, 266, 'Refund Date : ' + `${this.formatDateNTime(this.detailBill.updated_at)}`)
        doc.setTextColor(220, 20, 60)
        doc.text(15, 272, 'หมายเหตุ : ' + `${this.detailBill.note_cancel}`)
      }

      doc.setTextColor(0, 0, 0)
      doc.line(15, 276, 193, 276)

      //ลายเซนต์
      if (this.signature.message != 'error') {
        this.imgSignature.src = this.imgSig
        var typeImgs = this.checkTypeImage(this.imgSig)
        doc.text(163, 258, '(' + this.signature.signature_name + ')')
        doc.addImage(this.imgSignature, typeImgs, 159, 235, ratioImg.width, ratioImg.height)
      }

      doc.setDrawColor(90, 90, 90)
      doc.line(152, 260, 193, 260)
      doc.text(163, 265, 'ลายมือชื่อดิจิทัล')
      doc.text(152, 271, 'วันที่ ' + this.formatDateNTime(this.detailBill.created_at))

      doc.setFontSize(18)
      doc.setFont('THSarabunNew', 'bold')
      doc.setTextColor(147, 112, 219)
      doc.text(15, 285, this.clinicAddress.phone)

      // var string = doc.output('datauristring')
      // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      // var x = window.open()
      // x.document.open()
      // x.document.write('<title>Invoice</title>')
      // x.document.write(embed)
      // x.document.close()
      window.open(doc.output('bloburl'))
      // doc.save('Invoice' + '.pdf')
    },

    billPDFsmall() {
      var length = this.receiptList.length

      const doc = new jsPDF('p', 'mm', [80, length * 5 + (length + length + 130)])

      doc.addFileToVFS('THSarabunNew Bold.ttf', THSrabunBold)
      doc.addFileToVFS('THSarabunNew.ttf', THSrabunnew)
      doc.addFont('THSarabunNew Bold.ttf', 'THSarabunNew', 'bold')
      doc.addFont('THSarabunNew.ttf', 'THSarabunNew', 'normal')
      doc.setFont('THSarabunNew', 'bold')

      var width = doc.internal.pageSize.getWidth()
      var height = doc.internal.pageSize.getHeight()

      // var imgSmall =  new Image()

      // this.imageSmall.src = this.logoBranch
      var typeImg = this.checkTypeImage(this.logoBranch)

      var calImg = function(width, height) {
        var ratio = Math.min(27 / width, 27 / height)
        return {
          width: width * ratio,
          height: height * ratio,
        }
      }
      var ratioImgS = calImg(this.imageSmall.width, this.imageSmall.height)

      doc.addImage(this.imageSmall, typeImg, 51, 1, ratioImgS.width, ratioImgS.height)

      doc.setFontSize(12)
      doc.text(this.clinicAddress.full_name, 5, 10)
      doc.text('Tax Invoice No. S' + this.detailBill.receipt_code, 5, 16)
      doc.text('Tax Invoice Date. ' + this.formatDate(this.detailBill.receipt_date), 5, 22)
      doc.text('ลูกค้า : ' + this.detailBill.user.fname, 5, 28)
      doc.text('ใบเสร็จรับเงิน/ใบกำกับภาษีอย่างย่อ', width / 2, 34, { align: 'center' })

      doc.autoTable({
        margin: { left: 5, right: 5 },
        startY: 39,
        theme: 'plain',
        showHead: 'firstPage',
        showFoot: 'lastPage',
        styles: {
          font: 'THSarabunNew',
          fontStyle: 'bold',
          fontSize: 12, //14px
          lineColor: [255, 255, 255],
          lineWidth: 0.01, //1px
          cellPadding: 1,
          textColor: [0, 0, 0],
          fillColor: [255, 255, 255], //false for transparent or number or array of number
          valign: 'middle', //top, middle, bottom
          halign: 'left', //left, center, right
          cellWidth: 'auto', //'auto', 'wrap' or a number
          overflow: 'linebreak', //visible, hidden, ellipsize or linebreak
        },

        headStyles: { fillColor: [245, 245, 245] },

        body: this.receiptList,

        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'left' },
          2: { halign: 'center' },
          3: { halign: 'right' },
        },
        columns: [
          { header: '', dataKey: 'amount' },
          { header: '', dataKey: 'receipt_name' },
          { header: '', dataKey: '' },
          { header: '', dataKey: 'total_pay' },
        ],
        didParseCell: function(data) {
          if (data.row.section === 'body' && data.column.dataKey === 'total_pay') {
            data.cell.text = data.cell.text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          if (data.row.section === 'body' && data.column.dataKey === 'receipt_name') {
          }
        },

        foot: [
          [
            { content: 'ราคารวม', colSpan: 3, styles: { halign: 'left' } },
            {
              content: `฿ ${this.formatFloat(this.price_total)}`,
              styles: {
                halign: 'right',
                fontSize: 12,
                overflow: 'visible',
                columnWidth: 'wrap',
              },
            },
          ],
          [
            {
              content: `ภาษีมูลค่าเพิ่ม ${
                this.detailBill.vat_type == 0 ? '' : this.detailBill.vat_type == 1 ? '(inc) 7%' : '(exc) 7%'
              }`,
              colSpan: 3,
              styles: { halign: 'left', textColor: [0, 0, 0] },
            },
            {
              content: `฿ ${this.detailBill.num_pay > 1 ? 0 : this.formatFloat(this.detailBill.vat_price)} `,
              styles: { halign: 'right', textColor: [0, 0, 0], overflow: 'visible', columnWidth: 'wrap' },
            },
          ],
          [
            { content: `ราคาสุทธิ`, colSpan: 3, styles: { halign: 'left', fontSize: 12, textColor: [0, 0, 0] } },
            {
              content: `฿ ${this.formatFloat(this.detailBill.total_pay)}`,
              styles: {
                halign: 'right',
                fontSize: 12,
                textColor: [0, 0, 0],
                overflow: 'visible',
                columnWidth: 'wrap',
              },
            },
          ],
          [
            {
              content: `*หมายเหตุ : ${this.detailBill.note}`,
              colSpan: 4,
              styles: { halign: 'left', fontSize: 12 },
            },
          ],
          [{ content: `#${this.detailBill.order_num}`, colSpan: 2, styles: { halign: 'left', fontSize: 12 } }],
          [
            {
              content: `${this.formatDateNTime(this.detailBill.created_at)}`,
              colSpan: 4,
              styles: { halign: 'center', fontSize: 12 },
            },
          ],
          [
            {
              content: '-------------------------------------------------------------',
              colSpan: 4,
              styles: { halign: 'center', fontSize: 12 },
            },
          ],
          [
            {
              content: `${this.clinicAddress.phone}`,
              colSpan: 4,
              styles: { halign: 'center', fontSize: 12 },
            },
          ],
          [
            {
              content: '-------------------------------------------------------------',
              colSpan: 4,
              styles: { halign: 'center', fontSize: 12 },
            },
          ],
        ],
      })

      window.open(doc.output('bloburl'))

      // var string = doc.output('datauristring')
      // var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
      // var x = window.open()
      // x.document.open()
      // x.document.write('<title>Invoice</title>')
      // x.document.write(embed)
      // x.document.close()
    },
  },
}
</script>
<style scoped>
#paper {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(82, 81, 81, 0.2);
}

#paper[size='A4'] {
  width: 21cm;
  height: 29.7cm;
}

.border-top {
  border-top: 2.3px solid rgb(228, 228, 228);
}

.border-bottom {
  border-bottom: 2.3px solid rgb(228, 228, 228);
}
</style>

<style lang="scss">
#billing tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>
